import React, { Component } from 'react';
import {
  withRouter
} from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Header from '../common/Header';
import logo from '../../images/logo.png';
import banner from '../../images/banner.jpg';
import CustomizedDialogs from '../common/dialog.js';

import MagazineItem from '../resource/MagazineItem';
import EgwItem from '../resource/EgwItem';
import VideoItem from '../resource/VideoItem';
import { RESOURCE_URL, UAT_RESOURCE_URL } from "../../constant/Constant";

import searchAction from '../../redux/api/searchApi';
import { searchError, searchSuccess, searchPending } from '../../redux/reducer/searchReducer';

import ShareIcon from '@material-ui/icons/Share';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getValue, getLocalLocale } from "../../constant/Locale";

import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
  WeiboShareButton
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
} from "react-share";

class SearchResult extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      searchType: "biblebook",
      contentVersion: getLocalLocale(),
      isCommentary: false,
      searchWord: "",
      subject: "",
      body: "",
      shouldShowDialog: false
    }
  }

  componentWillMount() {
    const { postSearchByWord } = this.props;

    const type = this.props.match.params.type;
    const word = this.props.match.params.word;
    const sid = this.props.match.params.start_bible_id;
    const eid = this.props.match.params.end_bible_id;
    const version = this.props.match.params.content_version;
    const option = this.props.match.params.option;

    this.setState({
      searchType: type,
      contentVersion: version,
      isCommentary: (option == "true"),
      searchWord: word
    });

    if( word ) {
        postSearchByWord(type,word,sid,eid,version,option);
    }
  }

  redirectToVerse(book_id, chapter_id, verse_id) {

    window.location = "/bibleBook/"+book_id+"/"+chapter_id+"/"+this.state.contentVersion;

  }

  getHighlightedText(text, highlight) {
      const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
      return <span> { parts.map((part, i) => 
          <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: 'bold', background: "yellow" } : {} }>
              { part }
          </span>)
      } </span>;
  }


  shareDialog(subject, body) {
    let url = "https://www.sdabible.org/";
    return (
      <DialogContent dividers>
        <div>
          <EmailShareButton className="shareIcon" 
                            subject={subject} 
                            body={subject + "\n" + body} url={url} children={
            <EmailIcon size={32} round={true} />
          } />

          <WhatsappShareButton className="shareIcon" title={subject + "\n" + body} url={url} children={
            <WhatsappIcon size={32} round={true} />
          } />

          <FacebookShareButton className="shareIcon" quote={subject + "\n" + body} url={url} children={
            <FacebookIcon size={32} round={true} />
          } />

          <TwitterShareButton className="shareIcon" title={subject + "\n" + body} url={url} children={
            <TwitterIcon size={32} round={true} />
          } />

          <LineShareButton className="shareIcon" title={subject + "\n" + body} url={url} children={
            <LineIcon size={32} round={true} />
          } />

          <WeiboShareButton className="shareIcon" title={subject + "\n" + body} url={url} children={
            <WeiboIcon size={32} round={true} />
          } />
        </div>
      </DialogContent>
    );
  }

  closeDialog() {
     this.setState({ 
       shouldShowDialog: false, 
       subject: "",
       body: ""
     }); 
  }

  handleShareChainOnClick(item) {
    this.setState({ 
      shouldShowDialog: true,
      subject: item['name'] + item['chapter_num'] + ":" + item['verse_num'],
      body: item['locale_contents_elt'],
    });
  }

  dialogTitle() {
    return (
      <div className="dialogTitleContainer">
        <p>
          {getValue('shareTitle')}
        </p>
      </div>
    );
  }

  dialogContent(subject, body) {
    return this.shareDialog( subject, body );
  }

  render() {

    const { error, pending, search_results } = this.props;
    var contents = [];

    if (search_results) {
      console.log(search_results);
      search_results.map((item) => {
        contents.push(item);
      });
    }
    
    return (
      <div>
        <Header />

        <CustomizedDialogs 
          shouldShow={this.state.shouldShowDialog} 
          mapTitle={this.dialogTitle()}
          dialogContent={this.dialogContent(this.state.subject, this.state.body)} 
          closeDialog={() => this.closeDialog()}
        />

        <div className="versesContainer">
          <div>
            <h1>{getValue('searchRecord')}</h1>
          </div>
          <div className="booksContainer" style={{ justifyContent: "flex-start" }}>
          {
            (this.state.searchType == "biblebook") ?
            (

              (contents || []).map((item) => {
                return (
                  <div className="verseContent" style={{ display: "flex", alignItems: "center", cursor: 'pointer', width: '100%' }} >
                    <div style={{ width: '100%' }} onClick={() => this.redirectToVerse(item['book_id'], item['chapter_id'], item['verse_id'])}>
                      <p style={{ fontWeight: "bold" }} >{item['name'] + item['chapter_num'] + ":" + item['verse_num']} <span className={(item['locale_commentaries']) ? "roundComm" : ""}>{(item['locale_commentaries']) ? getValue('shortCommentary') : ""}</span> </p>
                      <p>{this.getHighlightedText(item['locale_contents_elt'], this.state.searchWord)}</p>
                    </div>
                    <ShareIcon className="dialogBtn" onClick={() => this.handleShareChainOnClick(item)} />
                  </div>
                )
              })

            ) : (

              (this.state.searchType == "resource") ? (

                (contents || []).sort((b, a) => {
                  return (new Date(a['issue_date'].replace(' ', 'T')+"Z").getTime() - new Date(b['issue_date'].replace(' ', 'T')+"Z").getTime());
                }).map((item) => {
                    if (item.video_name != null) {

                      return(

                        <VideoItem 
                          id={item['video_id']}
                          name={item['video_name']}
                          desc={item['locale_descriptions_elt']}
                          cover={RESOURCE_URL+item['cover_image']}
                          url={item['link']}
                          source_name={item['video_source_name']}
                          second_url={(item['second_link']) ? item['second_link'] : ""}
                          second_source_name={(item['second_video_source_name']) ? item['second_video_source_name'] : ""}
                        />

                      )

                    } else {
                      return (

                          <MagazineItem
                            id={item['magazine_id']}
                            name={item['magazine_name']}
                            desc={item['locale_descriptions_elt']}
                            cover={RESOURCE_URL+item['cover_image']}
                            url={RESOURCE_URL+item['document_pdf']}
                           />

                        )

                    }
                  })

              ) : (

                (contents || []).map(item => {
                  return (

                          <EgwItem
                            id={item['book_id']}
                            name={item['book_name']}
                            desc={item['sege_cont'].substring(Math.max(0,item['sege_cont'].indexOf(this.state.searchWord)-30),Math.min(item['sege_cont'].indexOf(this.state.searchWord)+30,item['sege_cont'].length))}
                            highlight={this.state.searchWord}
                            cover={null}
                            url={"/egwBook/"+item['book_id']+"/"+item['chap_id']}
                           />

                        )
                })

              )

            )
            
          }
          </div>
        </div>

      </div>
    );

  }

}


const mapStateToProps = (state) => {
  console.log(state);
  return {
      error: searchError(state.searchReducer),
      search_results: searchSuccess(state.searchReducer),
      pending: searchPending(state.searchReducer)
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
    postSearchByWord: searchAction
}, dispatch)

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchResult));
